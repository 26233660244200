<!-- use tags -->
import type { BrowserConfig } from "@bugsnag/browser";
import bs from "@bugsnag/js";
import bsPerf, { DefaultRoutingProvider } from "@bugsnag/browser-performance";
export interface Input {
  config: BrowserConfig;
  route: string;
}

<lifecycle onMount() {
  bs.start({
    ...input.config,
    onError: function (event) {
      return (
        event.errors[0].errorMessage !==
        "Skipping view transition because skipTransition() was called."
      );
    },
  });
  bsPerf.start({
    apiKey: input.config.apiKey,
    appVersion: input.config.appVersion,
    autoInstrumentRouteChanges: false,
    logger: console,
    releaseStage: input.config.releaseStage,
    routingProvider: new DefaultRoutingProvider(() => input.route),
    settleIgnoreUrls: [/.*\.success\.pactflow\.io\/.*/],
  });
}/>
